import { DOCUMENT } from '@angular/common';
import {Component, HostListener, Inject, OnInit} from '@angular/core';
import {Title} from '@angular/platform-browser';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss']
})
export class AppComponent implements OnInit {
  title = 'client-sites'; // used for tests, will leave for now

  constructor(@Inject(DOCUMENT) private document,private titleService:Title) { }

  @HostListener('window:scroll', ['$event'])
  onWindowScroll(e) {
    if (window.pageYOffset > 130) {
      let element = this.document.getElementById('second-nav');
      element.classList.add('sticky');
    } else {
      let element = this.document.getElementById('second-nav');
      element.classList.remove('sticky'); 
    }
  }
  setAppTitle =() => {
    this.titleService.setTitle('BrightHorizons')
  }

  ngOnInit(): void {
    this.setAppTitle()
  }
}
