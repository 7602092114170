<div class="main" id="signup-form">
  <div class="hero-text text-uppercase">
      Your Complete <br> Elder Care <br> Solution
  </div>
  <div class="form-box text-center">
      <h3>Sign Up Now</h3>
      <ng-template [ngIf]="!formSent">
          <div class="input-box">
              <i class="material-icons">mail_outline</i>
              <input type="text" [(ngModel)]="email" placeholder="Email" />
          </div>
          <re-captcha
                  [(ngModel)]="formModel.recaptchaToken"
                  name="recaptchaToken"
                  required
                  #captchaControl="ngModel"
             >
          </re-captcha>
          <span *ngIf="formErrMsg" class="text-danger">
              {{ formErrMsg }}
          <br>
          </span>
          <p class="small"><em>Please use your company e-mail address to receive a free account.</em></p>
          <button class="btn" (click)="checkEmail()">Submit</button>
      </ng-template>
      <p *ngIf="formSent && !errored" class="form-message">
          Please check your email and follow the instructions.
      </p>
      <p *ngIf="formSent && errored" class="form-message error">
        Something went wrong. Please try again.
      </p>
      <p class="small">
          <em>Already have an account? Sign in </em><a href="{{ dashboardUrl }}"><strong>HERE!</strong></a>
      </p>
  </div>
</div>
<app-features></app-features>
<app-howitworks></app-howitworks>
