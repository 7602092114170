import { Injectable } from '@angular/core';
import { HttpClient, HttpResponse } from '@angular/common/http';
import { catchError, map } from 'rxjs/operators';
import { throwError as observableThrowError } from 'rxjs';
import { environment } from '../environments/environment';

@Injectable()
export class EmailService {
  private apiBaseUrl = environment.apiBaseUrl;

  constructor(private http: HttpClient) {

  }

  signup(email, recaptchaToken) {
    const options: {observe: 'response', responseType: 'json'} = {observe: 'response', responseType: 'json'}
    var body = {email: email,recaptchaToken: recaptchaToken};

    // TODO move logic for clientApp=EL to referer
    return this.http.post(this.apiBaseUrl + '/tokens/signup?clientApp=BH', body, options).pipe(
        map((res: HttpResponse<any>) => {
          if (res.status === 200) {
            return {'valid': true};
          } else {
            return {'result': false};
          }
        }),
        catchError((err: any) => {
          return observableThrowError(err);
       })
    );
  }
}
