import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { BaseLandingComponent } from './landing/base-landing.component';

export const routes: Routes = [
  {
    path: '',
    component: BaseLandingComponent
  }
];


@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule]
})
export class AppRoutingModule { }
