import { Component, OnInit } from '@angular/core';
import { EmailService } from '../../providers/email.service';
import { environment } from '../../environments/environment';


export interface FormModel {
  recaptchaToken?: string;
}
@Component({
  selector: 'app-base-landing',
  templateUrl: './base-landing.component.html',
  styleUrls: ['./base-landing.component.scss']
})
export class BaseLandingComponent implements OnInit {
  public formModel: FormModel = {};
  public email:string;
  public formSent:boolean = false;
  public errored = false;
  public dashboardUrl:string = environment.dashboardBaseUrl;
  public formErrMsg: string = null;
  constructor(private emailService:EmailService) { }

  ngOnInit(): void {
  }
  private isRecaptchaValid() {
    if (typeof this.formModel.recaptchaToken === 'undefined') {
      this.formErrMsg = 'Please solve the reCAPTCHA to continue.';
      return false;
    }
    return true;
  }
  checkEmail():void {
    this.formErrMsg = null;
    if (!this.isRecaptchaValid()) {
      return;
    }

    this.emailService.signup(this.email,this.formModel.recaptchaToken).subscribe((result) => {
      this.formSent = true;
      this.errored = false
    }, (error) => {
      this.formSent = true;
      this.errored = true
    });
  }

}
