<div id="features" class="container features-section">
    <h2 class="text-center">FEATURES</h2>
    <div id="carecoach" class="row jumbotron">
        <div class="col-md-6">
            <div class="text-center">
                <h2>Care Coach</h2>
                <h4>Need help with your caregiving journey?  Let a trained Elder Care Professional help.</h4>
            </div>
            <div>
                <ul>
                    <li>Referrals for:</li>
                    <ul>
                        <li>Legal Advice</li>
                        <li>Short or Long-Term Care</li>
                        <li>In-Home Safety Assessments</li>
                        <li>Financial Needs and Advice</li>
                    </ul>
                    <li>Care Consultations</li>
                    <li>Family Meetings</li>
                </ul>
            </div>
        </div>
        <div class="col-md-6">
            <img src="assets/img/care-coach.jpg" class="feature-image"/>
        </div>
    </div>

    <div id="safekeeper" class="row jumbotron">
        <div class="col-md-6 col-md-push-6">
            <div class="text-center">
                <h2>SafeKeeper</h2>
                <h4>Keep important information and documents in one place.  You choose which family members gets to see it.</h4>
            </div>
            <div>
                <ul>
                    <li>Will</li>
                    <li>Advance Directives</li>
                    <li>Contacts</li>
                    <li>Medications</li>
                    <li>Medical Conditions</li>
                    <li>Special Instructions</li>
                </ul>
            </div>
        </div>
        <div class="col-md-6 col-md-pull-6">
            <img src="assets/img/safekeeper.jpg" class="feature-image"/>
        </div>
    </div>

    <div id="familycalendar" class="row jumbotron">
        <div class="col-md-6">
            <div class="text-center">
                <h2>Family Calendar</h2>
                <h4>Get everyone on the same page with a shared calendar.</h4>
            </div>
            <div>
                <ul>
                    <li>Assign and manage tasks between family members</li>
                    <li>Track time spent on caregiving</li>
                    <li>Invite 3rd party caregivers, assign them tasks, track their time and keep a history of their notes on care activity</li>
                </ul>
            </div>
        </div>
        <div class="col-md-6">
            <img src="assets/img/family-calendar.jpg" class="feature-image"/>
        </div>
    </div>

    <div id="messaging" class="row jumbotron">
        <div class="col-md-6 col-md-push-6">
            <div class="text-center">
                <h2>Messaging</h2>
                <h4>Privately chat with family members or a care coach.</h4>
            </div>
            <div>
                <ul>
                    <li>Direct messaging with your care coach</li>
                    <li>Securely message family members to keep track of discussions</li>
                </ul>
            </div>
        </div>
        <div class="col-md-6 col-md-pull-6">
            <img src="assets/img/messaging.jpg" class="feature-image"/>
        </div>
    </div>

    <div id="picsharing" class="row jumbotron">
        <div class="col-md-6">
            <div class="text-center">
                <h2>Picture &amp; Video Sharing</h2>
                <h4>Easily share pictures and videos that are important to your family.</h4>
            </div>
            <div>
                <ul>
                    <li>Keeps It private</li> 
                    <li>Invite extended family and friends to share in the fun</li>
                    <li>No advertising or annoying ads, just images you want to see</li>
                </ul>
            </div>
        </div>
        <div class="col-md-6">
            <img src="assets/img/sharing.jpg" class="feature-image"/>
        </div>
    </div>

    <div id="seniortablet" class="row jumbotron">
        <div class="col-md-6 col-md-push-6">
            <div class="text-center">
                <h2>Senior Tablet Application</h2>
                <h4>Our specialized Senior Tablet Application keeps your loved ones connected.</h4>
            </div>
            <div>
                <ul>
                    <li>Apps for Apple and Android devices – Use your own device</li>
                    <li>Easily share pictures and videos</li>
                    <li>Easy one step messaging to any member of the family</li>
                    <li>Designed specifically for seniors with little technical knowledge or memory issues</li>
                </ul>
            </div>
        </div>
        <div class="col-md-6 col-md-pull-6">
            <img src="assets/img/tablet-app.jpg" class="feature-image"/>
        </div>
    </div>

    <div id="permissions" class="row jumbotron">
        <div class="col-md-6">
            <div class="text-center">
                <h2>Permissions</h2>
                <h4>Customize what each family member has access to by setting individual permissions.</h4>
            </div>
            <div>
                <ul>
                    <li>Select who gets invited to each calendar event, message strings and SafeKeeper items.</li>
                    <li>Fully customizable</li>
                    <li>Invite the grandkids, 3rd party caregivers, neighbors, friends and only share with them what you want them to see</li>
                    <li>Features can be turned on or off for each individual</li>
                </ul>
            </div>
        </div>
        <div class="col-md-6">
            <img src="assets/img/permissions.jpg" class="feature-image"/>
        </div>
    </div>
</div>
