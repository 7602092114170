<div id="howitworks" class="container">
  <h2>HOW IT <strong class="emphasize-word">WORKS</strong></h2>
  <div class="cards">
    <div class="card">
      <img src="/assets/img/howitworks/create-account-step.svg">
      <div class="card-content">
        <div class="card-title text-center">Create An Account</div>
      </div>
      <div class="card-step">step 1</div>
    </div>

    <div class="card">
      <img src="/assets/img/howitworks/family-in-heart.svg">
      <div class="card-content">
        <div class="card-title text-center">Tell Us Who You Are <br> Caring For</div>
      </div>
      <div class="card-step">step 2</div>
    </div>

    <div class="card">
      <img src="/assets/img/howitworks/customize-experience-step.svg">
      <div class="card-content">
        <div class="card-title text-center">Customize Your Experience</div>
      </div>
      <div class="card-step">step 3</div>
    </div>

    <div class="card">
      <img src="/assets/img/howitworks/request-a-care-coach-step.svg">
      <div class="card-content">
        <div class="card-title text-center">Request A Care Coach</div>
      </div>
      <div class="card-step">step 4</div>
    </div>
  </div>
  <div class="text-center">
    <a href="#signup-form" class="btn btn-scroll-to-top">Create An Account</a>
  </div>
</div>
