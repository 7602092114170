import { BrowserModule } from '@angular/platform-browser';
import { NgModule } from '@angular/core';
import { FormsModule } from '@angular/forms';
import { HttpClientModule } from '@angular/common/http';
import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { EmailService } from './../providers/email.service';
import { BaseLandingComponent } from './landing/base-landing.component';
import { FeaturesComponent } from './features/features.component';
import { HowitworksComponent } from './howitworks/howitworks.component';
import { HeaderComponent } from './header/header.component';
import { FooterComponent } from './footer/footer.component';
import { environment } from './../environments/environment';
import { RECAPTCHA_SETTINGS, RecaptchaFormsModule, RecaptchaModule, RecaptchaSettings } from 'ng-recaptcha';
@NgModule({
  declarations: [
    AppComponent,
    BaseLandingComponent,
    FeaturesComponent,
    HowitworksComponent,
    HeaderComponent,
    FooterComponent
  ],
  imports: [
    FormsModule,
    RecaptchaModule,
    RecaptchaFormsModule,
    BrowserModule,
    AppRoutingModule,
    HttpClientModule,
  ],
  providers: [
    EmailService,
    {
      provide: RECAPTCHA_SETTINGS,
      useValue: { siteKey: `${environment.recaptchaConfig.siteKey}` } as RecaptchaSettings,
    }
  ],
  bootstrap: [AppComponent]
})
export class AppModule { }
