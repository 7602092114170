import { DOCUMENT } from '@angular/common';
import { Component, Inject, OnInit } from '@angular/core';

@Component({
  selector: 'app-header',
  templateUrl: './header.component.html',
  styleUrls: ['./header.component.scss']
})
export class HeaderComponent implements OnInit {

  constructor(@Inject(DOCUMENT) private document) { }

  ngOnInit(): void {
  }

  scrollTo(id: string) {
    let element = this.document.getElementById(id);
    element.scrollIntoView({ behavior: 'smooth' });
  }
}
